// libs
import { produce } from 'immer';

// types
import { CouponPageState, IAction } from './types';
import { ActionTypes } from './actions';

const initialState = Object.freeze<CouponPageState>({
  isFetching: false,
  isCouponPageDataFetched: false,
  couponPageData: null,
  isSecondaryFetching: false,
  isFetchingError: false,
  isNotFound: false,
  title: 'Coupon Page',
  currentPage: 0,
  breadcrumbs: [],
  isCouponPageDataFetching: false,
});

export default (state: CouponPageState = initialState, action: IAction): CouponPageState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.FETCH_COUPONPAGE_DATA_PROGRESS: {
        draft.isFetching = true;
        break;
      }

      case ActionTypes.FETCH_COUPONPAGE_DATA_SUCCESS: {
        draft.isFetching = false;
        draft.couponPageData = payload; // Ensure proper state update
        break;
      }

      case ActionTypes.FETCH_COUPONPAGE_DATA_FAILED: {
        draft.isFetching = false;
        break;
      }

      default:
        return state;
    }
  });
