// defs
import { TransformedProductInfo, TransformedWidgetInfo } from './types';

export const DEFAULT_PRODUCT_INFO: TransformedProductInfo = {
  deliveryInfo: '',
  images: [],
  priceInfo: { originalPrice: 0, discountedPrice: 0, discount: 0 },
  ratingsInfo: {
    rating: '',
    ratingsCount: '',
  },
  sizeGuideURL: '',
  inventoryNudge: '',
  sizeOptions: { title: '', options: [] },
  subTitle: '',
  tags: [],
  title: '',
  type: '',
  inStock: true,
  actionURL: '',
  id: '',
  sku: '',
};

export const RECO_WIDGET_TYPE = 'similar_products';

export const DEFAULT_WIDGET_INFO: TransformedWidgetInfo = {
  fetchMoreProducts: false,
  products: [],
  pageNumber: 1,
};
