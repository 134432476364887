export const ActionTypes = {
  FETCH_SUGGESTIONS_DATA_PROGRESS: 'global_search/fetch_suggestions_data_progress',
  FETCH_DN_SEARCH_DATA_SUCCESS_MWEB: 'global_search/fetch_dn_search_data_success_mweb',
  FETCH_SUGGESTIONS_DATA_SUCCESS_MWEB: 'global_search/fetch_suggestions_data_success_from_api_mweb',
  FETCH_SUGGESTIONS_DATA_SUCCESS_DWEB: 'global_search/fetch_suggestions_data_success_from_api_dweb',
  FETCH_SUGGESTIONS_DATA_FAILED: 'global_search/fetch_suggestions_data_failed',
  FETCH_SUGGESTIONS_DATA_SUCCESS_FROM_SESSION_MWEB:
    'global_search/fetch_suggestions_data_success_from_session_mweb',
  FETCH_SUGGESTIONS_DATA_SUCCESS_FROM_SESSION_DWEB:
    'global_search/fetch_suggestions_data_success_from_session_dweb',
  HIDE_AUTO_COMPLETE: 'global_search/hide_auto_complete',
  FETCH_AUTOCOMPLETE_DATA_PROGRESS: 'global_search/autoComplete_fetching_in_progress',
  FETCH_AUTOCOMPLETE_DATA_SUCCESS: 'global_search/autoComplete_fetching_success',
  FETCH_AUTOCOMPLETE_DATA_FAILED: 'global_search/autoComplete_fetching_FAILED',
  CLEAR_AUTOCOMPLETE_DATA: 'global_search/clear_autocomplete_data',
};
