// constants
import { DEFAULT_PRODUCT_INFO } from './constants';

// defs
import { TransformedProductInfo, TransformedWidgetInfo } from './types';

export const transformProductInfo = (detailsAPIResponse: any): TransformedProductInfo => {
  try {
    const {
      title = '',
      subTitle = '',
      tag_list = [],
      sizeOptions,
      sizeGuideURL = '',
      productMedia = [],
      imageUrl,
      return_period: returnPeriod = 0,
      review_rating_json = { show_ratings: true, star_rating: '', star_rating_count: '' },
      price,
      discountedPrice,
      discount = 0,
      type,
      action_url: actionURL,
      inventory_nudge: inventoryNudge,
      isOutOfStock,
      id,
      sku,
    } = detailsAPIResponse;
    return {
      ...DEFAULT_PRODUCT_INFO,
      deliveryInfo: returnPeriod ? `${returnPeriod} days return & exchange` : '',
      images: productMedia.length
        ? productMedia.map((item: { url: string }) => item.url)
        : [imageUrl],
      priceInfo: {
        originalPrice: price,
        discountedPrice: discountedPrice,
        discount,
      },
      ratingsInfo:
        review_rating_json?.show_ratings === false || review_rating_json?.show_ratings === 'false'
          ? {
              rating: '',
              ratingsCount: '',
            }
          : {
              rating: review_rating_json?.star_rating || '',
              ratingsCount: review_rating_json?.star_rating_count || '',
            },
      sizeGuideURL,
      sizeOptions,
      subTitle,
      tags: tag_list?.map((item: { title: string }) => item.title),
      title,
      type,
      actionURL,
      inventoryNudge,
      inStock: Boolean(isOutOfStock === 0),
      id,
      sku,
    };
  } catch (error) {
    return DEFAULT_PRODUCT_INFO;
  }
};

export const transformWidgetInfo = (widgetsApiResponse: any = {}): TransformedWidgetInfo => ({
  fetchMoreProducts: widgetsApiResponse?.has_more || false,
  products: widgetsApiResponse?.products || [],
  pageNumber: 1,
});

export const getWidgetAlgo = (
  widgetAlgoConfig: { [key: string]: { algo: string } }[],
  widgetType: string
) => {
  let algo = '';

  try {
    widgetAlgoConfig.forEach((widgetAlgoItem) => {
      if (widgetAlgoItem[widgetType]) {
        algo = widgetAlgoItem[widgetType].algo;
      }
    });
  } catch (error) {
    // do nothing
  }

  return algo;
};
