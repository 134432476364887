export const ActionTypes = {
  FETCH_COUPONPAGE_DATA_PROGRESS: 'couponPage/fetch_couponpage_data_progress',
  FETCH_COUPONPAGE_DATA_SUCCESS: 'couponPage/fetch_couponpage_data_success',
  FETCH_COUPONPAGE_DATA_NOTFOUND: 'couponPage/fetch_couponpage_data_notfound',
  FETCH_COUPONPAGE_DATA_FAILED: 'couponPage/fetch_couponpage_data_failed',
  RESET_ERROR: 'couponPage/reset_error',
  SET_COUPONPAGE_DATA: 'couponPage/set_couponpage_data',
};

export const resetError = () => ({
  type: ActionTypes.RESET_ERROR,
});
